import React, {useEffect,useState} from 'react';
import './index.css'
import Menu from "../Pubilc/Menu/Menu";
import {useNavigate} from 'react-router-dom'
function Header(props) {
    //普通数据配置
    let options={
        technology:[
            {
                title:"iFree",
                description:"一个自由的技术分享社区",
                href:'http://localhost:3000'
            },
            {
                title:"Navigation",
                description:"精选的学习路线和学习资源",
                href:'http://localhost:3002/'
            },
            {
                title:"Create",
                description:"无限题材，自由创作，激发你的灵感。",
                href:'http://localhost:3003/login'
            },
        ],
        zoology:[
            {
                title:"交易测试",
                description:"一个融入即时通讯系统的C2C交易系统",
                href:'/dealTest'
            },
            {
                title: "去中心化信息校验",
                description:"一个针对去中心化的信息举报系统",
                href:'/infoCheck'
            },
            {
                title:"账本校验",
                description:"一种提升区块链账本校验和存储效率的新技术",
                href:'/accountCheck'
            },
        ],
        openSource:[
            {
                title:"开放API",
                description:"随时接入Free生态应用",
                href:'/openAPI'
            },
        ],
        url:window.location.pathname
    }
    //菜单状态
    let [menu,setMenu]=useState(false);
    //导航栏状态
    let [header,setHeader]=useState(false)

    //960px下的展开项
    let [menuSon,setMenuSon]=useState([false,false,false])

    let navigate=useNavigate()
    //改变菜单状态
    function changeMenu() {
        setMenu(!menu)
    }
    //移除聚焦时关闭菜单
    function closeMenu(){
        setMenu(false)
    }
    //副作用钩子
    useEffect(()=>{
        window.addEventListener('scroll',headerChange)
        return ()=>{
            //取消监听
            window.removeEventListener("scroll",headerChange)
        }
    },[])

    //scroll触发函数
    function headerChange() {
        if(window.pageYOffset>=25){
            setHeader(true)
        }else {
            setHeader(false)
        }
    }
    //改变子菜单状态
    function changeMenuSon(number) {
        menuSon[number]=!menuSon[number]
        setMenuSon([...menuSon])
    }

    return (
        <header id="header" className={header?"header-change-after":"header-change-before"} onMouseLeave={closeMenu}>
            <nav className="header-nav">
                <div className="header-logo cursor" onClick={()=>navigate('/')}>Free.</div>
                <div className={`header-menu ${menu?"header-menu-after":""}`}>
                    <div className="header-dropdown">
                       <div className="header-drop-button" onClick={()=>changeMenuSon(0)}>
                           <button className="header-button">实验生态</button>
                           <i className={`header-iconfont iconfont ${menuSon[0]?"iconfont-rotate":""}`}>&#xe651;</i>
                           <div className="header-placeholder"/>
                       </div>
                       <Menu state={menuSon[0]} menu={options.technology}/>
                    </div>
                    <div className="header-dropdown">
                        <div className="header-drop-button" onClick={()=>changeMenuSon(1)}>
                            <button className="header-button">技术探索</button>
                            <i className={`header-iconfont iconfont ${menuSon[1]?"iconfont-rotate":""}`}>&#xe651;</i>
                            <div className="header-placeholder"/>
                        </div>
                        <Menu state={menuSon[1]} menu={options.zoology}/>
                    </div>
                    <div className="header-dropdown">
                        <div className="header-drop-button" onClick={()=>changeMenuSon(2)}>
                            <button className="header-button">开源社区</button>
                            <i className={`header-iconfont iconfont ${menuSon[2]?"iconfont-rotate":""}`}>&#xe651;</i>
                            <div className="header-placeholder"/>
                        </div>
                        <Menu state={menuSon[2]} menu={options.openSource}/>
                    </div>
                    <div className="header-dropdown">
                        <button className="header-button" onClick={()=>navigate('/dynamic')}>团队动态</button>
                    </div>
                    <div className="header-dropdown" onClick={()=>navigate('/team')}>
                        <button className="header-button">关于我们</button>
                    </div>
                </div>
                <div className="header-login">
                    <button className={`header-button ${header?"header-button-after":"header-button-before"}`}>加入我们</button>
                    <div className={`menu-bar ${menu?"menu-bar-after":""} ${header?"menu-bg-after":""}`} onClick={changeMenu}>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
