import axios from 'axios'
//创建axios实例
const https=axios.create({
    baseURL:'http://localhost',
    headers:{
    },
    withCredentials:false,
    timeout:8000,
    validateStatus(status){
        return status>=200&&status<300
    }
})


//添加请求拦截
https.interceptors.request.use((config)=>{
    //发送请求之前，获取token并添加到请求头
    let access_token=localStorage.jwtToken
    if(!!access_token){
        config.headers.Authorization=access_token
    }
    return config
},(error)=>{
    //在请求错误情况下

    //将错误抛到业务代码
    error.data={
        message:"服务器异常！"
    }
    return Promise.reject(error)
})


//添加响应拦截
https.interceptors.response.use((response)=>{
    //获取状态码
    const status=response.status;
    const successInfo=status>=200&&status<400
    //处理成功响应
    if(successInfo){
        //弹窗提示信息
    }
    return  response.data

},(error)=>{
    //获取状态码
    const status=error.response.status;
    const errorInfo=status<200||status>=400
    //处理成功响应
    if(errorInfo){
       //弹窗提示信息
    }
    return Promise.reject(error)
})


//处理不同状态码对应的错误信息
const showStatus=(status)=>{
    let message="";
    switch (status) {
        case 400:
            message = '请求错误(400)';
            break;
        case 401:
            message = '未授权，请重新登录(401)';
            break;
        case 403:
            message = '拒绝访问(403)';
            break;
        case 404:
            message = '请求出错(404)';
            break;
        case 408:
            message = '请求超时(408)';
            break;
        case 500:
            message = '服务器错误(500)';
            break;
        case 501:
            message = '服务未实现(501)';
            break;
        case 502:
            message = '网络错误(502)';
            break;
        case 503:
            message = '服务不可用(503)';
            break;
        case 504:
            message = '网络超时(504)';
            break;
        case 505:
            message = 'HTTP版本不受支持(505)';
            break;
        default:
            message = `连接出错(${status})!`;
    }
    return message
}
export default https
