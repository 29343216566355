import React, { useEffect } from 'react';
import { useState } from 'react';
import './index.css';


let newArticle={
    title:"标题",
    describe:"未知描述语",
    imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg"
}
let newBlogData={
    teamBlogs:[
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        },
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        },
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        },
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        }
    ],
    techExplore:[
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        },
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        },
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        },
        {
            imgSrc:"http://www.freeaii.com/static/images/dynamic2.jpg",
            describe:"不知道写什么，所以这段话看了也白看"
        }
    ]
}

function Dynamic(props) {
    const [articleData,setArticleData]=useState(new Array(8).fill(newArticle))
    const [blogData,setBlogData]=useState(newBlogData)
    return (
        <div id="dynamic">
            <header className="dynamic-header"/>
           <div className="dynamic-content">
               <div className="dynamic-main">
                   <img className="dynamic-main-img" src="http://www.freeaii.com/static/images/dynamic2.jpg" alt=""/>
                   <div className="dynamic-describe">
                        <h1 className="dynamic-h1">标题</h1>
                        <span className="dynamic-span">这是一段不知道说什么的描述语</span>
                   </div>
               </div>
               <nav>
                    <ul className="dynamic-nav">
                        {
                            articleData.map((article,index)=>{
                                return (
                                    <li className={(index===3||index===4)?"dynamic-nav-li2":"dynamic-nav-li"} key={index}>
                                    <img className="dynamic-nav-img" src={article.imgSrc} alt=""/>
                                    <div className="dynamic-describe">
                                        <h1 className="dynamic-h1">{article.title}</h1>
                                        <p className="dynamic-p">{article.describe}</p>
                                    </div>
                                </li>
                                )
                            })
                        }
                    </ul>
               </nav>
               <div className="dynamic-recommend">
                    <div className="dynamic-recommend-left">
                        <h3>#标签1</h3>
                        <span>最新消息<br/>&<br/>团队博客</span>
                        <img src="http://www.freeaii.com/static/images/dynamic2.jpg" alt=""/>
                        <p>这是一段无用的文字，我也不知道写什么，所以想到什么就是什么</p>
                        <button>查看更多</button>
                    </div>
                   <div className="dynamic-recommend-right">
                    {
                        blogData.teamBlogs.map((blog,index)=>{
                            return(
                                <div className={index===0?"dynamic-recommend-long":"dynamic-recommend-short"} key={index}>
                                <img src={blog.imgSrc} alt=""/>
                                <span>{blog.describe}</span>
                            </div> 
                            )
                        })
                    }
                   </div>
               </div>
               <div className="dynamic-recommend">
                   <div className="dynamic-recommend-left">
                       <h3>#标签2</h3>
                       <span>研究成果<br/>&<br/>技术探索</span>
                       <img src="http:///www.freeaii.com/static/images/dynamic2.jpg" alt=""/>
                       <p>这是一段无用的文字，我也不知道写什么，所以想到什么就是什么</p>
                       <button>查看更多</button>
                   </div>
                   <div className="dynamic-recommend-right">
                   <div className="dynamic-recommend-right">
                    {
                        blogData&&blogData.techExplore.map((blog,index)=>{
                            return(
                                <div className={index<=1?"dynamic-recommend-middle":"dynamic-recommend-short"} key={index}>
                                <img src={blog.imgSrc} alt=""/>
                                <span>{blog.describe}</span>
                            </div> 
                            )
                        })
                    }
                   </div>
                   </div>
               </div>
           </div>
        </div>
    );
}

export default Dynamic;
