import {createStore,applyMiddleware} from "redux";
//处理异步任务的中间件
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from "./reducers";
import {userInit} from "./actions/user";

const store=createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)))

if(localStorage.jwtToken){
    let access_token=localStorage.jwtToken
    //尝试使用token获取用户信息
    store.dispatch(userInit(access_token))
}
export default store
