import React from 'react';
import './index.css'
function Home(props) {
    return (
        <article id="home">
            <section className="home-title">
                <div className="home-content">
                </div>
            </section>
            <div className="home-container">

            </div>
        </article>
    );
}

export default Home;
