import React, {Fragment} from 'react';
import {Route,Routes,Navigate} from 'react-router-dom'
//基础样式
import './less/basics.css'
//公共样式
import './less/public.css'
import HomePage from "./pages/Home";
function App(props) {
    return (
       <Fragment>
           <Routes>
               <Route path='/*' element={<HomePage/>}/>
               <Route path="*" element={<Navigate to='/*'/>}/>
           </Routes>
       </Fragment>
    );

}

export default App;
