import {ADD_USER_IDENTITY,INIT_USER_IDENTITY} from "../../variates";

const initState={
    user:{
        id:0,
        name:"",
        avatar:"",
        access_token:""
    },
}
function userReducer(preState=initState,action) {
    const {type,data}=action;
    switch (type) {
        case ADD_USER_IDENTITY:
            return {
                user:data
            }
        case INIT_USER_IDENTITY:
            return initState
        default:
            return preState
    }
}

export default userReducer
