import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/store";
import {BrowserRouter} from 'react-router-dom'
//控制跳转页面后的
import ScrollToTop from "./utils/ScrollToTop";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </Provider>
    </BrowserRouter>
);