import React, {useState} from 'react';
import './index.css'
function Footer(props) {
    const [info,setInfo]=useState([false,false,false])
    const [email,setEmail]=useState("")

    //改变状态
    function showInfo(num) {
        info[num]=!info[num]
        setInfo([...info])
    }
    //保存邮箱
    function saveEmail(event) {
        let {value}=event.target
        if(value.length<24){
            setEmail(value)
        }
    }

    return (
        <footer id="footer">
            <div className="footer-container">
                <div className="footer-info">
                    <div className="footer-info-sub">
                        <span className="footer-logo">Free.</span>
                        <div className="footer-sub">
                            <span>我们会不定期向您推送精选内容</span>
                            <input type="email" placeholder="输入邮箱订阅" value={email} onChange={saveEmail}/>
                            <i className="iconfont footer-iconfont-right cursor">&#xe556;</i>
                        </div>
                    </div>
                    <div className="footer-info-about">
                        <section className="footer-info-des">
                            <div className="footer-info-title" onClick={()=>showInfo(0)}>
                                <span>生态探索</span>
                                <i className={`footer-iconfont-two iconfont ${info[0]?"iconfont-rotate":""}`}>&#xe651;</i>
                            </div>
                            <ul className={info[0]?"ul-change-after":"ul-change-before"}>
                                <li>实验室</li>
                                <li>战略规划</li>
                                <li>成果展示</li>
                            </ul>
                        </section>
                        <section className="footer-info-des">
                            <div className="footer-info-title" onClick={()=>showInfo(1)}>
                                <span>联系我们</span>
                                <i className={`footer-iconfont-two iconfont ${info[1]?"iconfont-rotate":""}`}>&#xe651;</i>
                            </div>
                            <ul className={info[1]?"ul-change-after":"ul-change-before"}>
                                <li>客户服务</li>
                                <li>合作洽谈</li>
                                <li>媒体及投资者</li>
                            </ul>
                        </section>
                        <section className="footer-info-des">
                            <div className="footer-info-title" onClick={()=>showInfo(2)}>
                                <span>法律协议</span>
                                <i className={`footer-iconfont-two iconfont ${info[2]?"iconfont-rotate":""}`}>&#xe651;</i>
                            </div>
                            <ul className={info[2]?"ul-change-after":"ul-change-before"}>
                                <li>服务协议</li>
                                <li>隐私政策</li>
                                <li>知识产权</li>
                            </ul>
                        </section>
                    </div>
                    <div className="footer-info-follow">
                        <span>
                            关注我们
                        </span>
                        <section>
                            <img src="http://www.freeaii.com/static/svg/twitter.svg" alt=""/>
                            <img src="http://www.freeaii.com/static/svg/instagram.svg" alt=""/>
                            <img src="http://www.freeaii.com/static/svg/linkedin.svg" alt=""/>
                            <img src="http://www.freeaii.com/static/svg/facebook.svg" alt=""/>
                        </section>
                    </div>
                </div>
                <div className="footer-lan">
                    <i className="footer-iconfont iconfont">&#xe60b;</i>
                    <button>简体中文</button>
                </div>
                <div className="footer-info-privacy">
                    <div className="footer-declaration">
                        © Copyright 2022. All Rights Reserved.
                    </div>
                    <a className='footer-declaration' rel="noreferrer" href='https://beian.miit.gov.cn/' target="_blank">蜀ICP备2021006530号-1</a>
                    <section className="footer-privacy">
                        <button>法律声明</button>
                        <button>服务准则</button>
                        <button>cookie设置</button>
                    </section>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
