import React from 'react';
import './index.css'
function Menu(props) {
    function update() {

    }
    return (
        <ul className={`header-tech ${props.state?'header-tech-after':""}`}>
            {
                props.menu.map((obj,index)=>{
                    return (
                        <li key={index} onClick={update} className="cursor item-hover">
                            <a href={obj.href} target="_blank" rel="noopener noreferrer">
                                <h4>{obj.title}</h4>
                                <p>{obj.description}</p>
                            </a>
                        </li>
                        )
                })
            }
        </ul>
    );
}

export default Menu;
