import {CHANGE_LOGIN_STATE} from "../../variates";
const initState={
    loginState:-1
}
function loginReducer(preState=initState,action) {
    const {type,data}=action;
    switch (type) {
        case CHANGE_LOGIN_STATE:
            return {
                loginState: data
            }
        default:
            return preState
    }
}

export default loginReducer
