import {CHANGE_LOGIN_STATE, INIT_USER_IDENTITY} from "../../variates";

export const changeLoginState=(data)=>{
    return {
        type:CHANGE_LOGIN_STATE,
        data
    }
}
export const initUserIdentity=()=>{
    return{
        type:INIT_USER_IDENTITY,
        data:{
            id:0,
            name:"",
            avatar:"",
            access_token:""
        }
    }
}
export const userLoginOut=()=>{
    return dispatch=>{
        //移除localstorage
        localStorage.removeItem('jwtToken')
        //更改user状态
        dispatch(initUserIdentity())
        //改变登录状态
        dispatch(changeLoginState(-1))
    }
}
