import React, {Fragment} from 'react';
import Header from "../../components/Home/Header";
import Content from "../../components/Home/Content";
import Footer from "../../components/Home/Footer";
import {useSelector} from "react-redux";
function HomePage(props) {
    const {loginState}=useSelector(state => state.loginReducer)
    return (
        <Fragment>
            <Header/>
            <Content/>
            <Footer/>
        </Fragment>
    );
}

export default HomePage;
