import React from 'react';
import './index.css'
function Team(props) {
    return (
         <div id="team">
            <div className="team-head">
               <div className="team-name">
                   <h1>Free团队</h1>
                   <p className="team-margin">一群有趣的人,做着一些有趣的事儿</p>
               </div>
            </div>
            <div className="team-introduce">
                <div className="team_describe">
                    <p>Free是自由的象征，它是一个非营利组织，仅仅作为部分程序员的聚集地。</p>
                </div>
                <article className="team-article">
                    <div className="team-title">
                        <h2>开源团队</h2>
                    </div>
                    <div className="team-flex-column">
                        <p>作为一个有趣的团队，任何人都可以为Free做出贡献，无论你的兴趣领域或专业如何，我们坚信，你始终有你自己的独特之处。Free在前进过程中有许多具有挑战性的难题，包括但不限于：</p>
                        <ul className="team-ul">
                            <li><span>视觉和用户体验设计</span></li>
                            <li><span>构建应用程序和服务</span></li>
                            <li><span>文稿设计</span></li>
                            <li><span>功能测试和用户测试</span></li>
                            <li><span>数据交互</span></li>
                            <li><span>社区支持和外联</span></li>
                        </ul>
                        <p>没有比成为Free其中一员更为兴奋的事了，如果你想有所作为，想利用所学做一些有趣的事儿，Free将是一个很好的去处，Free社区是一个参与、好奇和乐于助人的集体，其行为守则旨在创造一个友好、安全和可接受、没有恐吓或骚扰的学术环境。</p>
                        <p>如果你想从0开始体验一个社区的成长，Free社区或许是一个很好的开始。每隔一段时间我们将会举行虚拟会议，展示最新的社区项目、当前的Free新闻和活动等。</p>
                    </div>
                </article>
                <article className="team-article">
                    <div className="team-title">
                        <h2>Free核心团队</h2>
                    </div>
                    <div className="team-flex-column">
                        <p>虽然Free主要是一个开源、社区驱动的项目，但社区优先事项由一个核心团队指导，该团队有助于确保战略路线和决定未来发展方向。</p>
                        <p>Free核心团队的许多人员可能并不是专业的编码人员，但是这丝毫不影响他们发挥出自己的优势领域，我们希望在Free和社区成员能够共同成长。</p>
                        <p>Free核心团队组织灵活且反应灵敏，以适应随着时间的推移不断变化的优先事项，会随着项目进度和其他因素做出灵活的决策，核心组织的当前目标包括但不限于：</p>
                        <ul className="team-ul">
                            <li><span>视觉和用户体验设计</span>：为确保产品拥有良好的UI和UX体验，我们将会尽最大努力设计每一个页面的UI展现和UX交互。</li>
                            <li><span>数据采集</span>：确保现有的产品在开发完成后有一定的数据支撑，以及一些必要的数据用来测试。</li>
                            <li><span>Web页面的开发</span>：确保能够设计出一个强大、性能良好、易于使用的产品，我们一直奋斗在前线，不断地迭代社区Web端的功能和交互体验。</li>
                            <li><span>版本控制</span>：确保现有的Free代码库和相关功能可以得到良好维护和迭代改进，以满足社区需求和未来目标。</li>
                        </ul>
                        <p>Free核心团队默认严格执行公开讨论和沟通的政策，以最大限度地让社区成员都有参与和反馈的机会。这意味着致力于在面向公众、异步友好的渠道(如<span><a href="https://github.com/Freeaii" target="_blank" rel="noreferrer">GitHub</a></span>和<span>Free公众号</span>)中沟通和讨论社区战略方向和其他高级别决策。例如，参与路线图和类似战略活动的呼吁将在Free官网和其他公众平台上宣布，有关未来战略工作的建议和讨论可以在Free官网中找到。</p>
                    </div>
                </article>
                <article className="team-article">
                    <div className="team-title">
                        <h2>加入我们</h2>
                    </div>
                    <div className="team-flex-column">
                        <p>Free会在官网不定期更新一些新的人才需求，您可以根据自身擅长的领域，来和我们取得联系，也可以订阅我们的官方邮件来获取最新通知。比如现在我们正需要一群对前端开发、后端开发、智能合约、加密技术感兴趣的小伙伴。</p>
                        <p>此外，可以在<span>此处</span>找到在Free核心团队工作的空缺职位。</p>
                    </div>
                </article>
            </div>
        </div>
    );
}

export default Team;
