import React, {Fragment} from 'react';
import {Routes,Route,Navigate} from 'react-router-dom'
import Home from "../HomePage";
import Team from "../HomeTeam";
import Dynamic from "../Dynamic";
function Content(props) {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/team' element={<Team/>}/>
                <Route path="/dynamic" element={<Dynamic/>}/>
                <Route path="*" element={<Navigate to="/" />}/>
            </Routes>
        </Fragment>
    );
}

export default Content;
