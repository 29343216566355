import {ADD_USER_IDENTITY} from "../../variates";
import https from "../../apis/https";
import {changeLoginState} from "./login";
//解析token

export const setCurrentUser=(data)=>{
    return {
        type:ADD_USER_IDENTITY,
        data
    }
}
//异步action,尝试初始化获取用户信息
export const userInit=(access_token)=>{
    return dispatch=>{
        //取出token中的信息
        https.get('/user/info').then(res=>{
            dispatch(setCurrentUser({
                ...res,
                access_token
            }))
            dispatch(changeLoginState(1))
            //载入信息，设置token
        },err=>{
            //什么都不干
        })
    }
}
